import { type App, type Plugin, Route, View } from "@pimo/pimo-app-builder";
import {
  ArtefactsProgressCard,
  GridLayout,
  NumberCard,
  TabsLayoutProps,
} from "@pimo/pimo-components";
import { FilterData } from "in-types";
import { getQuarterAndYear, IN_COLORS } from "in-utils";
import { generatePath } from "react-router-dom";

import { OEsStatusTable } from "../../components/oes-status-table/oes-status-table.tsx";
import { OverviewTitleCard } from "../../components/overview-title-card/overview-title-card.tsx";
import { InAppState } from "../app.tsx";
import { APP_ROUTES } from "../constants.ts";
import { fetchOEs } from "../helpers/fetch-helpers.ts";
import { getFilterOptions } from "../helpers/get-filter-options.ts";
import { convertToMillion } from "../utils/index.ts";

export class OverviewPlugin implements Plugin<InAppState, InAppState> {
  route?: Route;
  private view?: View<InAppState, TabsLayoutProps>;
  private app?: App<InAppState>;

  onRegister(app: App<InAppState>): void {
    this.app = app;
    this.view = app.createView({
      name: "Overview",
      layout: new GridLayout(),
    });
    this.buildRoute();
    this.buildPage();
  }

  private buildPage() {
    if (!this.view) {
      return;
    }

    const overviewTitleCard = this.view.addComponent({
      component: OverviewTitleCard,
      layoutProps: { xs: 12 },
    });

    overviewTitleCard.mapState((appState) => {
      const [currentQuarter, currentYear] = getQuarterAndYear();
      const filterData = appState.filterDialogData;
      const filterOptions = appState.filterDialogOptions;
      return {
        title: `OE Overview (${currentQuarter}/${currentYear})`,
        filterData: filterData,
        filterOptions: filterOptions,
        cardProps: { sx: { width: "100%" } },
      };
    });

    overviewTitleCard.on("filter:apply", async ({ payload }) => {
      if (!this.app) return;

      const groupOverview = await fetchOEs(payload, true);

      this.app.patchAppState({
        filterDialogData: payload,
        groupOverview,
      });
    });

    overviewTitleCard.on("filter:clear", async () => {
      if (!this.app) return;

      const groupOverview = await fetchOEs({} as FilterData, true);

      this.app.patchAppState({
        filterDialogData: {
          impactLeadFilter: [],
          oeNameFilter: [],
          regionFilter: [],
          searchQueryFilter: "",
          updatedFilter: [],
        },
        groupOverview,
      });
    });

    const numberOfOEsCard = this.view.addComponent({
      component: NumberCard,
      layoutProps: {
        xs: 12,
        md: 12 / 5,
      },
    });

    numberOfOEsCard.mapState((appState) => {
      const numberOfInitiatives = appState.groupOverview?.numberOfInitiatives;
      return {
        title: "# Of Initiatives",
        number: numberOfInitiatives ?? "",
        textColor: IN_COLORS.white,
      };
    });

    const totalImpactValueCard = this.view.addComponent({
      component: NumberCard,
      layoutProps: {
        xs: 12,
        sm: 12 / 2,
        md: 12 / 5,
      },
    });

    totalImpactValueCard.mapState((appState) => {
      const totalImpact = appState.groupOverview?.groupTotalImpactValue;
      const totalImpactValue = totalImpact?.value ?? 0;
      const targetYear = totalImpact?.targetYear ?? "";

      return {
        title: "Total Impact EOY " + targetYear,
        number: convertToMillion(totalImpactValue) + " Mn",
        textColor: IN_COLORS.white,
      };
    });

    const updatesCompletedCard = this.view.addComponent({
      component: NumberCard,
      layoutProps: {
        xs: 12,
        sm: 12 / 2,
        md: 12 / 5,
      },
    });

    updatesCompletedCard.mapState((appState) => {
      const updateStatus = appState.groupOverview?.groupUpdateStatus;
      const completed = updateStatus?.completed ?? "";
      const total = updateStatus?.total ?? "";
      return {
        title: "Updates Completed",
        number: `${completed}/${total}`,
        textColor: IN_COLORS.white,
      };
    });

    const updatesOverdueCard = this.view.addComponent({
      component: NumberCard,
      layoutProps: {
        xs: 12,
        sm: 12 / 2,
        md: 12 / 5,
      },
    });

    updatesOverdueCard.mapState((appState) => {
      const updateStatus = appState.groupOverview?.groupUpdateStatus;
      const overdue = updateStatus?.overdue ?? "";
      const total = updateStatus?.total ?? "";

      return {
        title: "Updates Overdue",
        number: `${overdue}/${total}`,
        textColor: IN_COLORS.white,
      };
    });

    const overallProgressCard = this.view.addComponent({
      component: ArtefactsProgressCard,
      layoutProps: {
        xs: 12,
        sm: 12 / 2,
        md: 12 / 5,
      },
    });

    overallProgressCard.mapState((appState) => {
      const updateStatus = appState.groupOverview?.groupUpdateStatus;
      const progress = parseFloat(
        (
          ((updateStatus?.completed ?? 0) / (updateStatus?.total ?? 1)) *
          100
        ).toFixed()
      );

      return {
        cardTitle: "Overall Progress",
        color: IN_COLORS.lightBlue,
        value: progress,
        displayValue: `${progress} %`,
      };
    });

    const oesStatusTable = this.view.addComponent({
      component: OEsStatusTable,
      layoutProps: {
        xs: 12,
      },
    });

    oesStatusTable.mapState((appState) => {
      const oeOverview = appState.groupOverview?.oeOverview || [];
      const totalImpact = appState.groupOverview?.groupTotalImpactValue;
      const targetYear = totalImpact?.targetYear ?? "";

      return {
        oes: oeOverview,
        targetYear,
      };
    });

    oesStatusTable.on("oes-status-table:oe-click", ({ payload }) => {
      if (!payload?.id) {
        return;
      }

      this.app?.navigate(
        generatePath(APP_ROUTES.operatingEntities, {
          oeId: payload.id,
          viewname: "dashboard",
        })
      );
    });
  }

  private buildRoute() {
    if (!this.app || !this.view) {
      return;
    }

    this.route = this.app.createRoute({
      path: `${APP_ROUTES.overview}`,
      view: this.view,
    });

    this.route?.on("load", async () => {
      if (!this.app) {
        return;
      }

      const groupOverview = await fetchOEs();
      const filterDialogOptions = getFilterOptions(groupOverview.oeOverview, [
        "impactLead",
        "oeName",
        "oeRegion",
      ]);

      this.app.patchAppState({
        groupOverview,
        filterDialogData: {
          impactLeadFilter: [],
          oeNameFilter: [],
          regionFilter: [],
          searchQueryFilter: "",
          updatedFilter: [],
        },
        filterDialogOptions,
        isLoading: false,
      });
    });
  }
}
